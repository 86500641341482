<template>
  <v-row class="customer-detail custom-border-to">
    <v-col md="12" sm="12" class="pt-0">
      <div class="new-white-box-overview-main nav">
        <div class="col-md-12 py-0 customer-image">
          <Status :status="customerActivated"></Status>
          <span>
            <v-chip
              outlined
              color="blue"
              label
              v-if="customer.company_type == 'company'"
            >
              <span class="font-size-16"> Company </span>
            </v-chip>
            <v-chip
              outlined
              color="red"
              label
              v-if="customer.company_type == 'personal'"
            >
              <span class="font-size-16"> Individual </span>
            </v-chip>
          </span>
          <div class="py-4">
            <v-skeleton-loader
              class="custom-skeleton"
              v-if="pageLoading"
              type="image"
            >
            </v-skeleton-loader>
            <template v-else>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="getProfileImage ? getProfileImage : $defaultProfileImage"
                aspect-ratio="1"
                max-height="140"
                max-width="140"
                class="margin-auto custom-grey-border custom-border-radius-50"
                transition="fade-transition"
              >
              </v-img>
            </template>
          </div>
        </div>
        <div class="col-md-12 pt-0 px-0">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="text@5"
          >
          </v-skeleton-loader>

          <table v-else class="width-100">
            <template v-if="customer.company_type == 'company'">
              <tr v-if="false">
                <td class="pb-2 font-size-18 text-capitalize">Security Code</td>
                <td class="pb-2 font-size-18 text-capitalize">
                  {{ customer.security_code }}
                </td>
              </tr>
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-16 color-custom-blue py-1 text-uppercase"
                >
                  Company Information
                </th>
              </tr>
              <tr>
                <td
                  class="py-1 font-size-18 text-capitalize font-weight-500"
                  width="120"
                >
                  Customer #
                </td>
                <td class="py-1">
                  <div
                    class="font-weight-600 font-size-18"
                    v-if="customer.company_type == 'company'"
                  >
                    <v-skeleton-loader
                      class="custom-skeleton"
                      v-if="pageLoading"
                      type="button"
                    >
                    </v-skeleton-loader>
                    <Barcode v-else :barcode="getBarcode"></Barcode>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  class="py-1 font-size-18 text-capitalize font-weight-500"
                  valign="top"
                >
                  Company Name
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="customer.company_name">{{
                    customer.company_name
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No company name</em></template
                  >
                </td>
              </tr>
              <tr>
                <td
                  class="py-1 font-size-18 text-capitalize font-weight-500"
                  valign="top"
                >
                  Display Name
                </td>
                <td
                  class="py-1 font-size-18 text-capitalize"
                  v-if="customer && customer.display_name"
                >
                  {{ customer.display_name }}
                </td>
                <em v-else class="text-muted ml-2"> no display name</em>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize font-weight-500">
                  Email
                </td>
                <td class="py-1 font-size-18">
                  <template v-if="customer.company_email">{{
                    customer.company_email
                  }}</template>
                  <template v-else
                    ><em class="text-muted"> no email</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize font-weight-500">
                  Phone No.
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="customer.company_number">{{
                    customer.company_number
                  }}</template>
                  <template v-else
                    ><em class="text-muted">no phone number</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize font-weight-500">
                  fax
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="customer.company_fax">{{
                    customer.company_fax
                  }}</template>
                  <template v-else><em class="text-muted">No fax</em></template>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize font-weight-500">
                  Category
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="customer.category">
                    <v-chip
                      :color="customer?.category_color"
                      small
                      label
                      outlined
                      >{{ customer.category }}</v-chip
                    >
                  </template>
                  <template v-else
                    ><em class="text-muted">No category</em></template
                  >
                </td>
              </tr>
              <tr v-if="customer?.tags?.length">
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-16 color-custom-blue py-1 text-uppercase"
                >
                  Tags
                </th>
              </tr>
              <tr v-if="customer.tags && customer.tags.length > 0">
                <td class="font-size-18 py-1 font-weight-500" colspan="2">
                  <template v-if="customer.tags && customer.tags.length > 0">
                    <v-chip
                      small
                      v-for="(row, index) in customer.tags"
                      :key="index"
                      class="mr-2"
                      text-color="white"
                      :color="row.color"
                      >{{ row.text }}</v-chip
                    >
                  </template>
                  <em v-else class="text-muted"> no tags</em>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-16 color-custom-blue py-1 text-uppercase"
                >
                  Contact Information
                </th>
              </tr>
              <tr>
                <td
                  class="py-1 font-size-18 text-capitalize font-weight-500"
                  width="120"
                >
                  Customer #
                </td>
                <td class="py-1">
                  <div
                    class="font-weight-600 font-size-18"
                    v-if="customer.company_type == 'personal'"
                  >
                    <v-skeleton-loader
                      class="custom-skeleton"
                      v-if="pageLoading"
                      type="button"
                    >
                    </v-skeleton-loader>
                    <Barcode v-else :barcode="getBarcode"></Barcode>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize font-weight-500">
                  Full Name
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  {{ customer.default_person?.full_name }}
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Display Name</td>
                <!--   <pre>{{ customer }}</pre> -->
                <td
                  class="py-1 font-size-18 text-capitalize"
                  v-if="
                    customer &&
                    customer.default_person &&
                    customer.default_person.display_name
                  "
                >
                  {{ customer.default_person.display_name }}
                </td>
                <em v-else class="text-muted ml-2"> no display name</em>
              </tr>
              <tr>
                <td class="py-1 font-size-18 font-weight-500">Email Address</td>
                <td
                  class="py-1 font-size-18"
                  v-if="
                    customer &&
                    customer.default_person &&
                    customer.default_person.primary_email
                  "
                >
                  {{ customer.default_person.primary_email }}
                </td>
                <td v-else><em class="text-muted"> no email address</em></td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize font-weight-500">
                  Mobile Number
                </td>
                <td
                  class="py-1 font-size-18 text-capitalize"
                  v-if="
                    customer &&
                    customer.default_person &&
                    customer.default_person.primary_phone
                  "
                >
                  {{ customer.default_person.primary_phone }}
                </td>
                <td v-else class="pb-2">
                  <em class="text-muted"> no mobile number</em>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize font-weight-500">
                  Landline Number
                </td>
                <td
                  class="py-1 font-size-18 text-capitalize"
                  v-if="
                    customer &&
                    customer.default_person &&
                    customer.default_person.did
                  "
                >
                  {{ customer.default_person.did }}
                </td>
                <td v-else class="py-1">
                  <em class="text-muted"> no Landline Number</em>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 font-weight-500 text-capitalize">
                  Category
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="customer.category">
                    <v-chip
                      :color="customer?.category_color"
                      small
                      label
                      outlined
                      >{{ customer.category }}</v-chip
                    >
                  </template>
                  <template v-else
                    ><em class="text-muted">No category</em></template
                  >
                </td>
              </tr>
              <tr v-if="customer?.tags?.length">
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-16 text-uppercase color-custom-blue py-1"
                >
                  Tags
                </th>
              </tr>
              <tr v-if="customer.tags && customer.tags.length > 0">
                <td class="font-size-18 py-1" colspan="2">
                  <template v-if="customer.tags && customer.tags.length > 0">
                    <v-chip
                      small
                      v-for="(row, index) in customer.tags"
                      :key="index"
                      class="mr-2"
                      text-color="white"
                      :color="row.color"
                      >{{ row.text }}</v-chip
                    >
                  </template>
                  <em v-else class="text-muted"> no tags</em>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";

export default {
  name: "contact-details",
  title: "Customer",
  mixins: [CommonMixin, FileManagerMixin],
  data() {
    return {
      imageLoading: false,
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      let customerId =
        _this.customer && _this.customer.id ? _this.customer.id : 0;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param, customerId, "customer")
        .then((response) => {
          _this.customer.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    getDetailType(param) {
      if (param === 1) {
        return "Email";
      }
      return "Phone";
    },
  },
  components: {
    Status,
    Barcode,
  },
  computed: {
    customerActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.customer) === false) {
        return _this.customer.activated;
      }
      return false;
    },
    fullname() {
      let name = "";
      const _this = this;
      if (
        _this.customer &&
        _this.lodash.isEmpty(_this.customer.persons) === false
      ) {
        for (let i = 0; i < _this.customer.persons.length; i++) {
          if (_this.customer.persons[i].default) {
            name =
              _this.lodash.startCase(_this.customer.persons[i].title) +
              ". " +
              _this.customer.persons[i].first_name +
              " " +
              _this.customer.persons[i].last_name;
          }
        }
      }
      return name;
    },
    primaryEmail() {
      let email = "";
      const _this = this;
      if (
        _this.customer &&
        _this.lodash.isEmpty(_this.customer.details) === false
      ) {
        for (let i = 0; i < _this.customer.details.length; i++) {
          if (_this.customer.details[i].type === 1) {
            email = _this.customer.details[i].value;
            break;
          }
        }
      }
      return email;
    },
    primaryPhone() {
      let phone = "";
      const _this = this;
      if (
        _this.customer &&
        _this.lodash.isEmpty(_this.customer.details) === false
      ) {
        for (let i = 0; i < _this.customer.details.length; i++) {
          if (_this.customer.details[i].type === 2) {
            phone = _this.customer.details[i].value;
            break;
          }
        }
      }
      return phone;
    },
    getProfileImage() {
      if (!this.customer.profile_logo) {
        return null;
      }
      return this.customer.profile_logo.file.url;
    },
    getBarcode() {
      if (!this.customer.barcode) {
        return "";
      }
      return this.customer.barcode;
    },
  },
};
</script>
