<template>
  <v-row class="customer-detail custom-border-to">
    <v-col md="12" sm="12" class="pt-0">
      <div class="new-white-box-overview-main nav">
        <div class="col-md-12 pt-0 px-0">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="text@5"
          >
          </v-skeleton-loader>

          <table v-else class="width-100">
            <template v-if="customer.company_type == 'company'">
              <tr v-if="false">
                <td class="pb-2 font-size-18 text-capitalize">Security Code</td>
                <td class="pb-2 font-size-18 text-capitalize">
                  {{ customer.security_code }}
                </td>
              </tr>
              <tr>
                <td
                  width="200"
                  class="py-1 font-size-18 font-weight-500 text-capitalize"
                >
                  Customer Type
                </td>
                <td class="py-1">
                  <span>
                    <v-chip
                      small
                      outlined
                      color="blue "
                      label
                      v-if="customer.company_type == 'company'"
                    >
                      <span class="font-size-16"> Company </span>
                    </v-chip>
                    <v-chip
                      small
                      outlined
                      color="red"
                      label
                      v-if="customer.company_type == 'personal'"
                    >
                      <span class="font-size-16"> Individual </span>
                    </v-chip>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 font-weight-500 text-capitalize">
                  Full Name
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  {{ fullname }}
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 font-weight-500">UEN</td>
                <td class="py-1 font-size-18 text-lowercase">
                  <template v-if="customer.uen">{{ customer.uen }}</template>
                  <template v-else><em class="text-muted">No uen</em></template>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 font-weight-500 text-capitalize">
                  Website
                </td>
                <td class="py-1 font-size-18 text-lowercase">
                  <template v-if="customer.website">{{
                    customer.website
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No Website</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 font-weight-500 text-capitalize">
                  Customer Ref #
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="customer.sap_customer_no">{{
                    customer.sap_customer_no
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No Customer Ref #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 font-weight-500 text-capitalize">
                  Ref No.
                </td>
                <td class="py-1 font-size-18 text-lowercase">
                  <template v-if="customer.cust_vend_ref_no">{{
                    customer.cust_vend_ref_no
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No Ref No.</em></template
                  >
                </td>
              </tr>
              <tr v-if="false">
                <td class="py-1 font-size-18 font-weight-500 text-capitalize">
                  Entity
                </td>
                <td class="py-1 font-size-18 text-lowercase">
                  <template v-if="customer.entity">{{
                    customer.entity
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No Entity</em></template
                  >
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-if="false">
                <td
                  width="200"
                  class="py-1 font-size-18 font-weight-500 text-capitalize"
                >
                  Customer Type
                </td>
                <td class="py-1">
                  <span>
                    <v-chip
                      small
                      color="blue white--text"
                      label
                      v-if="customer.company_type == 'company'"
                    >
                      <span class="font-size-16"> Company </span>
                    </v-chip>
                    <v-chip
                      small
                      color="red white--text"
                      label
                      v-if="customer.company_type == 'personal'"
                    >
                      <span class="font-size-16"> Individual </span>
                    </v-chip>
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  class="py-1 font-size-18 font-weight-500 text-capitalize"
                  width="200"
                >
                  Name
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  {{ customer.default_person?.full_name }}
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 font-weight-500 text-capitalize">
                  Customer Ref #
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="customer.sap_customer_no">{{
                    customer.sap_customer_no
                  }}</template>
                  <template v-else>
                    <em class="text-muted">No customer ref #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 font-weight-500 text-capitalize">
                  Ref No.
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="customer.cust_vend_ref_no">{{
                    customer.cust_vend_ref_no
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No ref no.</em></template
                  >
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";

export default {
  name: "contact-details",
  title: "Customer",
  mixins: [CommonMixin, FileManagerMixin],
  data() {
    return {
      imageLoading: false,
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      let customerId =
        _this.customer && _this.customer.id ? _this.customer.id : 0;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param, customerId, "customer")
        .then((response) => {
          _this.customer.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    getDetailType(param) {
      if (param === 1) {
        return "Email";
      }
      return "Phone";
    },
  },
  components: {},
  computed: {
    customerActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.customer) === false) {
        return _this.customer.activated;
      }
      return false;
    },
    fullname() {
      let name = "";
      const _this = this;
      if (
        _this.customer &&
        _this.lodash.isEmpty(_this.customer.persons) === false
      ) {
        for (let i = 0; i < _this.customer.persons.length; i++) {
          if (_this.customer.persons[i].default) {
            name =
              _this.lodash.startCase(_this.customer.persons[i].title) +
              ". " +
              _this.customer.persons[i].first_name +
              " " +
              _this.customer.persons[i].last_name;
          }
        }
      }
      return name;
    },
    primaryEmail() {
      let email = "";
      const _this = this;
      if (
        _this.customer &&
        _this.lodash.isEmpty(_this.customer.details) === false
      ) {
        for (let i = 0; i < _this.customer.details.length; i++) {
          if (_this.customer.details[i].type === 1) {
            email = _this.customer.details[i].value;
            break;
          }
        }
      }
      return email;
    },
    primaryPhone() {
      let phone = "";
      const _this = this;
      if (
        _this.customer &&
        _this.lodash.isEmpty(_this.customer.details) === false
      ) {
        for (let i = 0; i < _this.customer.details.length; i++) {
          if (_this.customer.details[i].type === 2) {
            phone = _this.customer.details[i].value;
            break;
          }
        }
      }
      return phone;
    },
    getProfileImage() {
      if (!this.customer.profile_logo) {
        return null;
      }
      return this.customer.profile_logo.file.url;
    },
    getBarcode() {
      if (!this.customer.barcode) {
        return "";
      }
      return this.customer.barcode;
    },
  },
};
</script>
